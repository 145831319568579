$colorsMarketplace_primary: #e5173f;

$colorsUni4life_header: #f7f7fa;
$colorsUni4life_primary: #e5173f;
$colorsUni4life_primaryOpacity078: rgba(0, 87, 121, 0.78);
$colorsUni4life_primaryOpacity050: rgba(0, 87, 121, 0.5);
$colorsUni4life_primaryOpacity010: rgba(0, 87, 121, 0.1);
$colorsUni4life_rectangleArticlePopular: #cca741;
$colorsUni4life_bubbleEvents: #dae7dd;

$bradesco_primary: #e1173f;
$bradesco_gray_light: #e4e5e9;
$bradesco_black: #47484c;
$bradesco_gray_medium: #a7a8ac;
$bradesco_gray_dark: #3c3c3c;

$bradesco_blue: #3b69ff;

$colorsCommon_primary: #e5173f;
$colorsCommon_secundary: #78849e;
$colorsCommon_secondary: $colorsCommon_secundary;
$colorsCommon_tertiary: #eaeaea;
$colorsCommon_textTitle: #2a2e43;
$colorsCommon_background: #ebebeb;
$colorsCommon_placeholder: rgba(69, 79, 99, 0.5);

$hideHeaderShadow: true;
