@import "../customer.scss";

.row-flex-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


:export {
  colorsMarketplace_primary: $colorsMarketplace_primary;

  colorsUni4life_header:$colorsUni4life_header;
  colorsUni4life_primary:$colorsUni4life_primary;
  colorsUni4life_primaryOpacity078:$colorsUni4life_primaryOpacity078;
  colorsUni4life_primaryOpacity050:$colorsUni4life_primaryOpacity050;
  colorsUni4life_primaryOpacity010:$colorsUni4life_primaryOpacity010;
  colorsUni4life_rectangleArticlePopular:$colorsUni4life_rectangleArticlePopular;
  colorsUni4life_bubbleEvents:$colorsUni4life_bubbleEvents;

  colorsCommon_primary:$colorsCommon_primary;
  colorsCommon_secundary:$colorsCommon_secundary;
  colorsCommon_tertiary:$colorsCommon_tertiary;
  colorsCommon_textTitle:$colorsCommon_textTitle;
  colorsCommon_background:$colorsCommon_background;
  hideHeaderShadow:$hideHeaderShadow;
}
