.pincode-input-container {
  display: flex;
  justify-content: center;
}

.pincode-input-container input {
  margin: 0 5px !important;
  border-color: #78849e !important;
  border-radius: 5px;
  font-weight: 600;
  color: #2a2e43;
}
