@import "src/styles/variables";
.ie-banner {
  height: 50px;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background: #fffcb8;
  font-weight: 600;
  color: #c70009;
  font-size: 16px; }



.float-menu {
  ul {
    display: block !important;
    background: white;
    min-width: 162px;
    top: 68px;
    position: absolute;
    z-index: 99;
    box-shadow: -1px 8px 18px #454f6373;
    border-radius: 10px;

    a:hover {
        text-decoration: none !important; }


    .icon-content {
      font-size: 16px !important;
      width: 22px;
      height: 21px;
      display: flex;
      justify-content: center; }

    li {
      cursor: pointer;
      color: $colorsCommon_primary;
      background-color: transparent;
      font-size: 14px;
      list-style-type: none;
      text-align: left;
      padding: 15px 12px 12px 15px;
      transition: 0.2s;
      display: flex;
      align-items: center;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      span {
        color: $colorsCommon_primary; }

      span:first-child {
        margin-right: 10px;
        font-size: 22px; } }

    li:hover {
      background-color: $colorsUni4life_primary; }

    li:hover span {
      color: white !important; }

    li:hover span::before {
      color: white !important; }

    a:last-child:hover {
      li {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px; } }

    a:first-child:hover {
      li {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px; } } } }

.container-header-secondary {
  background-color: $colorsUni4life_primary;
  height: 68px;
  padding: 0 10vw 0 10vw;

  .button-back {
    cursor: pointer; }

  >span {
    font-size: 15px;
    color: white !important;
    display: flex;
    align-items: baseline;
    position: absolute; }

  .icon-back {
    margin-right: 10px; }

  >div {
    margin: auto;
    display: flex;
    align-items: center;

    a {
      height: 38px; }

    img {
      height: 100%; } } }

.header-desktop {
  display: flex;
  padding: 15px 0; }

@media only screen and (max-width: 426px) {
  .navbar {
    justify-content: space-between !important; }

  .container-header-secondary {

    >span {
      font-size: 12px;
      position: initial; }

    >div {
      margin: 0; } } }
